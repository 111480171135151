/** @jsxImportSource theme-ui */
import React, { useEffect } from 'react'

// functions
import LocomotiveScroll from 'locomotive-scroll'

// components
import Seo from '../utils/seo'
import TransButton from '../components/trans-button'

// style
import '../style/404.scss'

const Contatti = () => {
  useEffect(() => {
    // init Locomotive Sroll and ScrollTrigger
    const scroll = new LocomotiveScroll({
      el: document.querySelector('.scroll-section'),
      smooth: true,
      multiplier: 0.75,
      firefoxMultiplier: 45,
      lerp: 0.1,
      smartphone: {
        breakpoint: 0,
        smooth: false,
      },
      tablet: {
        breakpoint: 0,
        smooth: false,
      },
    })

    function callback(mutationList) {
      mutationList.forEach((mutation) => {
        switch (mutation.type) {
          case 'attributes':
            switch (mutation.attributeName) {
              case 'menu-status':
                const state = document
                  .querySelector('[menu-status]')
                  .getAttribute('menu-status')
                if (state === 'close') {
                  scroll.start()
                }
                if (state === 'open') {
                  scroll.stop()
                }
                break
              default:
                break
            }
            break
          default:
            break
        }
      })
    }

    // Obsever section
    const targetNode1 = document.querySelector('[menu-status]'),
      targetNode2 = document.querySelector('[main-visible-status]')
    // observe menu for changes
    const observer1 = new MutationObserver(callback),
      observer2 = new MutationObserver(callback)
    // Start observing the target node for configured mutations
    observer1.observe(targetNode1, {
      attributes: true,
      attributeFilter: ['menu-status'],
      childList: false,
      subtree: false,
    })
    observer2.observe(targetNode2, {
      attributes: true,
      attributeFilter: ['main-visible-status'],
      childList: false,
      subtree: false,
    })
    setTimeout(() => {
      scroll.update()
    }, 10)

    return () => {
      // stop observing
      observer1.disconnect()
      observer2.disconnect()
      // destroy Locomotive Scroll instace on page change
      scroll.destroy()
    }
  }, [])

  return (
    <>
      <Seo
        title='Pagina non trovata'
        description=''
      />
      <section className='container-404'>
        <h1 sx={{ color : 'primary' }}>
          Errore 404
        </h1>
        <p>
          La pagina che stai cercando sembra non esistere (ancora). 
        </p>
        <p>
          Riprova tra un po' di tempo!
        </p>
        <TransButton to='/'>
          Torna alla home
        </TransButton>
      </section>
    </>
  )
}

export default Contatti
